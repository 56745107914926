import React, { MouseEvent, useState, useEffect } from 'react'
import Dialog from 'react-modal'

import styles from './SolConfirmationModal.module.scss'
import SolCancelSave from 'SolComponents/SolCancelSave/SolCancelSave'
import { TestingProps } from 'shared/types/TestingProps'
import SolCheckbox from '../SolCheckbox/SolCheckbox'
import { PodsLoader } from 'components/Loaders/PodsLoader'

export interface ConfirmationModalProps extends TestingProps {
  isOpen?: boolean
  modalText?: JSX.Element | string
  children?: any
  onAcceptingAction: (e: MouseEvent<HTMLButtonElement>) => void
  acceptingText?: string
  acceptingDisabled?: boolean
  isLoading?: boolean
  onCloseModal: () => void
  cancelText?: string
  subHeaderText?: string | JSX.Element | string[]
  cancelDisabled?: boolean
  hideCancel?: boolean
  dontShowAgainCheckbox?: boolean | string
  onDontShowCheckboxChange?: (value: boolean) => void
}

function SolConfirmationModal({
  isOpen = true,
  modalText,
  children,
  onCloseModal,
  onAcceptingAction,
  acceptingText = 'Confirm',
  acceptingDisabled,
  cancelText = 'Cancel',
  subHeaderText,
  cancelDisabled,
  dataTestId,
  hideCancel = false,
  dontShowAgainCheckbox = false,
  onDontShowCheckboxChange,
  isLoading,
}: ConfirmationModalProps) {

  const [loading, setLoading] = useState(false)
  const [localCheckboxState, setLocalCheckboxState] = useState<boolean>(false)

  const acceptClick = (e: MouseEvent<HTMLButtonElement>) => {
    onAcceptingAction(e)
    if (dontShowAgainCheckbox && localCheckboxState) {
      onDontShowCheckboxChange?.(localCheckboxState)
    }
    setLoading(true)
  }

  useEffect(() => {
    if (!isOpen && loading) {
      setLoading(false)
    }
  }, [isOpen])

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modal} data-testid={dataTestId}>
        {isLoading ? (<>
          <span className={styles.modalText}>Please wait...</span>
          <PodsLoader size="massive" />
        </>)
          : (<>
            {modalText && <span className={styles.modalText}>{modalText}</span>}
            {subHeaderText ? (
              <p className={styles.subHeaderText}>
                <span>
                  {
                    Array.isArray(subHeaderText)
                      ? subHeaderText.join(' ')
                      : subHeaderText
                  }
                </span>
              </p>
            ) : null}
            {children}
            {dontShowAgainCheckbox && (
              <SolCheckbox
                label="Don't show this again"
                checked={localCheckboxState}
                onClick={() => {
                  setLocalCheckboxState(!localCheckboxState)
                }}
              />
            )}
            <div className={styles.modalButton}>
              <SolCancelSave
                cancelText={cancelText}
                acceptingText={acceptingText}
                acceptingDisabled={acceptingDisabled}
                cancelDisabled={cancelDisabled}
                cancelClick={onCloseModal}
                hideCancel={hideCancel}
                acceptingClick={acceptClick}
                loading={loading}
              />
            </div>
          </>)}
      </div>
    </Dialog>
  )
}

Dialog.setAppElement('body')

export default SolConfirmationModal
