import React, { useState } from 'react'
import { Card, Header } from 'semantic-ui-react'
import classNames from 'classnames'
import styles from './SolFilterHeaderCard.module.scss'
import { AppliedFilters } from 'components/DataTableSlim/AppliedFilters'
import { SolRow } from 'SolComponents'
import SideFilterMenu from './SideFilterMenu/SideFilterMenu'
import { DateRangeOptions } from 'SolComponents/SolChart/Components/TimeInterval'
import FilterButton from 'components/FilterButton'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { DateRange } from './DateRange'
import { OptionDate } from 'shared/types/OptionDate'
import { ChartTimePeriod } from 'SolComponents/SolChart/types'
import { TimeInterval, useTotalDisplaysNoViewQuery } from 'graphql/__generated__/types'
import { pluralize } from 'shared/core/utils'
import { PodsLoader } from 'components/Loaders/PodsLoader'

export type Props = {
  header: string
  filteredItems?: number
  children?: React.ReactNode
  shadow?: boolean
  className?: string
  tableId: DataTableInstance
  customFilter?: JSX.Element
  dateRange?: {
    include: boolean
    options?: DateRangeOptions
  }
  timeInterval?: TimeInterval
  filterDisabled?: boolean
  subHeaderDisabled?: boolean
  getTotalPods?: () => number
  setSearchValue?: (searchValue: string) => void
  searchValue?: string
  handleIntervalChange?: (interval: ChartTimePeriod, optionDate: OptionDate) => void
  setTimeInterval?: (interval: TimeInterval) => void
  loading?: boolean
}

export const SolFilterHeaderCard = ({
  header,
  filteredItems,
  children,
  shadow,
  className,
  tableId,
  customFilter,
  dateRange,
  timeInterval,
  filterDisabled,
  subHeaderDisabled,
  getTotalPods = () => useTotalDisplaysNoViewQuery().data?.totalDisplays ?? 0,
  setSearchValue,
  searchValue,
  handleIntervalChange,
  setTimeInterval,
  loading,
}: Props) => {
  const [filterDrawerIsOpen, setFilterDrawerIsOpen] = useState(false)

  // AppliedFilters & SideFilterMenu useses useDataTable which sets up
  // the CategoryFilters but dose not rely on the a dataTable-
  // it relies on the context from the  DataTableSlimProvider so for
  // the UserPage we will need a unique tableId to use this component but wont need to implement the whole table

  const toggleFilter = () => setFilterDrawerIsOpen(!filterDrawerIsOpen)
  const totalPods = getTotalPods()
  return (
    <>
      <Card
        className={classNames(styles.solCard, className, {
          [styles.shadow]: shadow !== false,
        })}
      >
        <Card.Content>
          <div>
            <Card.Header className={styles.header}>
              <Header as="h2">
                {header}
                {loading ? (
                  <PodsLoader size="tiny" />
                ) : (
                  !subHeaderDisabled && (
                    <Header.Subheader>
                      {`${filteredItems ?? 0}/${pluralize(totalPods ?? 0, ' Pod')}`}
                    </Header.Subheader>
                  )
                )}
              </Header>
            </Card.Header>
            <>
              <SolRow className={styles.topPillsContainer}>
                <div className={styles.button}>
                  <FilterButton onClick={toggleFilter} disabled={filterDisabled} />
                </div>
                <div className={styles.pills}>
                  <div className={styles.customFilter}>{customFilter}</div>
                  <div className={styles.appliedFilters}>
                    <AppliedFilters
                      setSearchValue={setSearchValue}
                      searchValue={searchValue}
                      tableId={tableId}
                      topFilter
                      totalPods={totalPods}
                    />
                  </div>
                </div>
              </SolRow>
            </>
          </div>
          {dateRange?.include && (
            <div className={styles.dateRange}>
              <DateRange
                timeInterval={timeInterval}
                handleIntervalChange={handleIntervalChange}
                setInterval={setTimeInterval}
                options={dateRange.options}
              />
            </div>
          )}
          {children}
        </Card.Content>
      </Card>
      {filterDrawerIsOpen && (<SideFilterMenu
        tableId={tableId}
        filterDrawerIsOpen={filterDrawerIsOpen}
        changeFilterDrawer={() => setFilterDrawerIsOpen(!filterDrawerIsOpen)}
        totalDisplays={totalPods}
      />)}
    </>
  )
}

export default SolFilterHeaderCard
