import { useState, useEffect, useMemo } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  AllDisplaysQuery,
  AllDisplaysQueryVariables,
  Display,
  AllDisplaysDocument,
  TotalDisplaysDocument,
  TotalDisplaysQueryVariables,
  TotalDisplaysQuery, OrderByDisplay, Template,
} from 'graphql/__generated__/types'
import { DataTableInstance } from 'components/DataTableSlim/DataTableSlim'
import { useSelectedItems } from 'components/DataTableSlim/Hooks/useSelectedItems'
import { useDisplaySearchOptions } from './useDisplaySearchOptions'
import { ServerSelectedItems } from 'components/DataTableSlim/types'

export const useAllPods = (isAllPodsPage?: boolean) => {
  const [tableData, setTableData] = useState<Display[]>([])
  const [initialLoadHappened, setInitialLoadHappened] = useState(false)
  const selectedItems = useSelectedItems<Display>(DataTableInstance.AllPods)
  const displaySearchOptions = useDisplaySearchOptions()
  const { categories, searchTerm, managementStatus, locationFilters } = displaySearchOptions

  const { data: tableQueryResponse, loading, networkStatus, refetch: refetchDisplays } = useQuery<
    AllDisplaysQuery,
    AllDisplaysQueryVariables
  >(AllDisplaysDocument, {
    variables: {
      options: displaySearchOptions,
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
  })

  const { data: totalDisplaysResponse } = useQuery<
    TotalDisplaysQuery,
    TotalDisplaysQueryVariables
  >(TotalDisplaysDocument, {
    variables: {
      options: {
        ...displaySearchOptions,
        ...(isAllPodsPage ? {} : { isManageable: true }),
      },
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
  })

  const [
    fetchPrimarySelectedItems,
    { data: allSelectedItemsResult }] = useLazyQuery<AllDisplaysQuery, AllDisplaysQueryVariables>(
      AllDisplaysDocument,
      {
        fetchPolicy: 'cache-and-network',
      },
    )

  const totalSelectableItems
    = totalDisplaysResponse?.displays?.totalRecords ?? 0
  const [
    fetchTotalSelectedItems,
    { data: selectedItemsResult, loading: loadingTotalSelectedItems, networkStatus: selectedPodsNetworkStatus },
  ] = useLazyQuery<AllDisplaysQuery, AllDisplaysQueryVariables>(
    AllDisplaysDocument,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  const allSelectedItems = useMemo(() => {
    return selectedItemsResult?.displays?.items
  }, [selectedItemsResult])
  const selectedItemsIds = allSelectedItems
    ?.map(display => display?.id)
    .filter((id): id is string => typeof id === 'string') || []

  useEffect(() => {
    if (selectedItems?.includedIds?.length) {
      const options = {
        managementStatus,
        excludeDisplayIds: selectedItems.excludedIds,
        displayIds: selectedItems.includedIds,
        searchTerm,
        categories,
        locationFilters,
        page: 1,
        records: 999999,
        ...(isAllPodsPage ? {} : { isManageable: true }),
      }
  
      fetchPrimarySelectedItems({
        variables: {
          options,
        },
      })
    }
  }, [
    selectedItems?.includedIds,
    managementStatus,
    selectedItems?.excludedIds,
  ])
  
  const selectedFailedOnboardingItemsIds = useMemo(() => {
    return (
      allSelectedItemsResult?.displays?.items
        ?.filter(item => item?.managementStatus !== 'Unsupported')
        ?.map(display => display?.id)
        .filter((id): id is string => typeof id === 'string') || []
    )
  }, [allSelectedItemsResult])

  let numSelectedItems = selectedItemsResult?.displays?.totalRecords ?? selectedItems.includedIds?.length ?? 0
  // When no pods are selected, it should default to 0 instead of including all:
  if (selectedItems.includedIds?.length === 0) {
    numSelectedItems = 0
  }
  const selectedPod = selectedItemsResult?.displays?.items?.[0]
  const selectedPodOptions = {
    managementStatus,
    excludeDisplayIds: selectedItems.excludedIds,
    displayIds: selectedItems.includedIds,
    searchTerm,
    categories,
    locationFilters,
    page: 1,
    records: selectedItems.includedIds?.includes('*') ? 999999 : 1, // TODO: Dirty hack, create limit prop in PodOptions type
    ...(isAllPodsPage ? {} : { isManageable: true }),
  }
  useDeepCompareEffect(() => {
    if (selectedItems.includedIds && selectedItems.includedIds.length > 0) {
      fetchTotalSelectedItems({
        variables: {
          options: selectedPodOptions,
        },
      })
    }
  }, [selectedPodOptions])

  const data = tableQueryResponse?.displays?.items as Display[]
  const totalItems = tableQueryResponse?.displays?.totalRecords ?? 0

  const sortByTemplateType = (displays: Display[], orderBy: OrderByDisplay): Display[] => {
    if (!orderBy?.settingsGroupType) {
      return displays
    }

    const sortedData = [...displays]

    const isAssigned = (template?: Template | null) => template && template?.name && template.name !== 'Unassigned'

    sortedData.sort((a, b) => {
      const matchingTemplateA = a.assignedTemplates
        .find(t => t?.settingsGroup?.type === orderBy.settingsGroupType)
      const matchingTemplateB = b.assignedTemplates
        .find(t => t?.settingsGroup?.type === orderBy.settingsGroupType)

      const assignedA = isAssigned(matchingTemplateA?.template)
      const assignedB = isAssigned(matchingTemplateB?.template)

      if (assignedA !== assignedB) {
        return assignedA ? -1 : 1
      }

      if (assignedA && assignedB) {
        const nameA = matchingTemplateA?.template?.name.toUpperCase() || ''
        const nameB = matchingTemplateB?.template?.name.toUpperCase() || ''
        return orderBy.direction === 'ASC' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA)
      }

      return 0
    })

    return sortedData
  }

  useEffect(() => {
    if (data) {
      if (displaySearchOptions?.orderBy?.settingsGroupType) {
        const sortedData = sortByTemplateType(
          data,
          displaySearchOptions.orderBy,
        )
        setTableData(sortedData)
      } else {
        setTableData(data)
      }
      setInitialLoadHappened(true)
    }
  }, [data])

  const onSelect = (result: ServerSelectedItems) => {
    const variables = {
      options: {
        excludeDisplayIds: result.excludedIds,
        displayIds: result.includedIds,
        searchTerm,
        categories,
        locationFilters,
        ...(isAllPodsPage ? {} : { isManageable: true }),
        records: result.includedIds?.includes('*') ? 999999 : 1, // TODO: Dirty hack, create limit prop in PodOptions type
      },
    }
    fetchTotalSelectedItems({
      variables,
    })
  }

  return {
    tableData,
    initialLoadHappened,
    loading,
    networkStatus,
    totalSelectableItems,
    totalItems,
    loadingTotalSelectedItems,
    onSelect,
    refetchDisplays,
    numSelectedItems,
    selectedItems,
    displaySearchOptions,
    selectedPod,
    selectedItemsIds,
    selectedFailedOnboardingItemsIds,
    selectedPodsNetworkStatus,
  }
}
