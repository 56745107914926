import { Loader } from 'semantic-ui-react'
import React from 'react'
import styles from './PodsLoader.module.scss'

interface Props {
  size: 'tiny' | 'massive'
}

export const PodsLoader = ({ size }: Props) => {
  const containerClass = styles[`${size}Container`] || styles.tinyContainer
  
  return (
    <div className={containerClass}>
      <Loader size={size} active inline />
    </div>
  )
}
