import { Filter, FilterType } from '../types'
import {
  useDeleteRebootedAlertFiltersMutation,
  useDeleteUnreachableAlertFiltersMutation,
  useDeleteTemplateAlertFiltersMutation,
  AlertConfigDocument,
  AlertConfigQuery,
  useDeleteUsbPlugAlertFiltersMutation,
} from 'graphql/__generated__/hasura-types'
import isMatch from 'lodash/isMatch'
import { DataProxy } from '@apollo/client'

export const useDeleteAlertFilters = () => {
  const [deleteRebootedAlertFilters] = useDeleteRebootedAlertFiltersMutation()
  const [deleteUnreachableAlertFilters] = useDeleteUnreachableAlertFiltersMutation()
  const [deleteTemplateAlertFilters] = useDeleteTemplateAlertFiltersMutation()
  const [deleteUsbPlugAlertFilters] = useDeleteUsbPlugAlertFiltersMutation()

  return (filterType: FilterType, filter: Partial<Filter>) => {
    const mutation = {
      [FilterType.DisplayUnreachable]: deleteUnreachableAlertFilters,
      [FilterType.DisplayRebooted]: deleteRebootedAlertFilters,
      [FilterType.DisplayOutOfTemplate]: deleteTemplateAlertFilters,
      [FilterType.UsbDevice]: deleteUsbPlugAlertFilters,
    }[filterType]

    mutation({
      variables: filter,
      update: (proxy: DataProxy) => {
        const cacheProp = {
          [FilterType.DisplayUnreachable]: 'unreachableFilters',
          [FilterType.DisplayRebooted]: 'rebootedFilters',
          [FilterType.DisplayOutOfTemplate]: 'templateFilters',
          [FilterType.UsbDevice]: 'usbFilters',
        }[filterType]
        const cacheData = proxy.readQuery({
          query: AlertConfigDocument,
        }) as AlertConfigQuery
        const currentFilters = cacheData?.[cacheProp] || []
        const newVal = currentFilters.filter((cacheFilter: Filter) => !isMatch(cacheFilter, filter))
        proxy.writeQuery({
          query: AlertConfigDocument,
          data: {
            ...cacheData,
            [cacheProp]: newVal,
          },
        })
      },
    })
  }
}
